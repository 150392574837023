import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Logout from './Logout';
import axios from 'axios';

const AdminDashboard = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [newEntry, setNewEntry] = useState({ name: '', country: '', city: '', category: '' });
  const [editEntry, setEditEntry] = useState(null);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', role: '' });
  const [cookies] = useCookies(['role']);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.role !== 'admin') {
      navigate('/login');
    } else {
      fetchData();
      fetchUsers();
    }
  }, [cookies, navigate]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://tensura.me/api/data');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://tensura.me/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleAdd = async () => {
    if (Object.values(newEntry).some(value => !value)) return;
    try {
      await axios.post('https://tensura.me/api/data', newEntry);
      fetchData();
      setNewEntry({ name: '', country: '', city: '', category: '' });
    } catch (error) {
      console.error('Error adding data:', error);
    }
  };

  const handleEdit = async (id) => {
    if (!editEntry) return;
    try {
      await axios.put(`https://tensura.me/api/data/${id}`, editEntry);
      fetchData();
      setEditEntry(null);
    } catch (error) {
      console.error('Error editing data:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://tensura.me/api/data/${id}`);
      fetchData();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleUserAdd = async () => {
    if (Object.values(newUser).some(value => !value)) return;
    try {
      await axios.post('https://tensura.me/api/users', newUser);
      fetchUsers();
      setNewUser({ username: '', password: '', role: '' });
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleUserDelete = async (id) => {
    try {
      await axios.delete(`https://tensura.me/api/users/${id}`);
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto p-5 bg-gray-50 rounded-lg shadow-lg">
      <Logout />
      <h1 className="text-3xl font-bold mb-4 text-gray-800">Admin Dashboard</h1>
      <input
        type="text"
        placeholder="Search..."
        className="border rounded p-2 mb-4 w-full shadow-inner focus:ring-2 focus:ring-blue-400"
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery}
      />

      {/* Add New Data Section */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Soal"
          className="border rounded p-2 shadow-md"
          value={newEntry.name}
          onChange={(e) => setNewEntry({ ...newEntry, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Jawaban"
          className="border rounded p-2 mx-2 shadow-md"
          value={newEntry.country}
          onChange={(e) => setNewEntry({ ...newEntry, country: e.target.value })}
        />
        <input
          type="text"
          placeholder="Result"
          className="border rounded p-2 mx-2 shadow-md"
          value={newEntry.city}
          onChange={(e) => setNewEntry({ ...newEntry, city: e.target.value })}
        />
        <input
          type="text"
          placeholder="Category"
          className="border rounded p-2 mx-2 shadow-md"
          value={newEntry.category}
          onChange={(e) => setNewEntry({ ...newEntry, category: e.target.value })}
        />
        <button
          onClick={handleAdd}
          className="bg-blue-600 text-white p-2 rounded shadow-md hover:bg-blue-700 transition duration-300"
        >
          Add Data
        </button>
      </div>

      {/* Data List Section */}
      <h2 className="text-2xl font-semibold mb-2">Data List</h2>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-blue-500 text-white">
            <th className="border px-4 py-2">ID</th>
            <th className="border px-4 py-2">Soal</th>
            <th className="border px-4 py-2">Jawaban</th>
            <th className="border px-4 py-2">Result</th>
            <th className="border px-4 py-2">Category</th>
            <th className="border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(item => (
            <tr key={item.id} className="border-b hover:bg-gray-100">
              <td className="border px-4 py-2">{item.id}</td>
              <td className="border px-4 py-2">
                {editEntry?.id === item.id ? (
                  <input
                    type="text"
                    value={editEntry.name}
                    onChange={(e) => setEditEntry({ ...editEntry, name: e.target.value })}
                    className="border rounded p-1 w-full"
                  />
                ) : (
                  item.name
                )}
              </td>
              <td className="border px-4 py-2">
                {editEntry?.id === item.id ? (
                  <input
                    type="text"
                    value={editEntry.country}
                    onChange={(e) => setEditEntry({ ...editEntry, country: e.target.value })}
                    className="border rounded p-1 w-full"
                  />
                ) : (
                  item.country
                )}
              </td>
              <td className="border px-4 py-2">
                {editEntry?.id === item.id ? (
                  <input
                    type="text"
                    value={editEntry.city}
                    onChange={(e) => setEditEntry({ ...editEntry, city: e.target.value })}
                    className="border rounded p-1 w-full"
                  />
                ) : (
                  item.city
                )}
              </td>
              <td className="border px-4 py-2">
                {editEntry?.id === item.id ? (
                  <input
                    type="text"
                    value={editEntry.category}
                    onChange={(e) => setEditEntry({ ...editEntry, category: e.target.value })}
                    className="border rounded p-1 w-full"
                  />
                ) : (
                  item.category
                )}
              </td>
              <td className="border px-4 py-2">
                {editEntry?.id === item.id ? (
                  <button
                    onClick={() => handleEdit(item.id)}
                                        className="bg-green-500 text-white p-2 rounded mx-2"
                                    >
                                        Save
                                    </button>
                                ) : (
                                    <button onClick={() => setEditEntry(item)} className="bg-yellow-500 text-white p-2 rounded mx-2">
                                        Edit
                                    </button>
                                )}
                                <button onClick={() => handleDelete(item.id)} className="bg-red-500 text-white p-2 rounded">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* User Management Section */}
            <h2 className="text-2xl font-semibold mt-5 mb-2">User Management</h2>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Username"
                    className="border rounded p-2"
                    value={newUser.username}
                    onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                />
                <input
                    type="password"
                    placeholder="Password"
                    className="border rounded p-2 mx-2"
                    value={newUser.password}
                    onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                />
                <select
                    className="border rounded p-2 mx-2"
                    value={newUser.role}
                    onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                >
                    <option value="">Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                </select>
                <button onClick={handleUserAdd} className="bg-blue-600 text-white p-2 rounded shadow-md hover:bg-blue-700 transition duration-300">
                    Add User
                </button>
            </div>
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr className="bg-blue-500 text-white">
                        <th className="border px-4 py-2">ID</th>
                        <th className="border px-4 py-2">Username</th>
                        <th className="border px-4 py-2">Role</th>
                        <th className="border px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id} className="border-b hover:bg-gray-100">
                            <td className="border px-4 py-2">{user.id}</td>
                            <td className="border px-4 py-2">{user.username}</td>
                            <td className="border px-4 py-2">{user.role}</td>
                            <td className="border px-4 py-2">
                                <button onClick={() => handleUserDelete(user.id)} className="bg-red-500 text-white p-2 rounded">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminDashboard;
