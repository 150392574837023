import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie'; // Import useCookies
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Dashboard = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState([]); // Initialize empty array
    const [selectedCategory, setSelectedCategory] = useState('');
    const [, , removeCookie] = useCookies(['role', 'username']); // Hook for cookies
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await fetch('https://tensura.me/api/data'); // Update to your API endpoint
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setData(jsonData);

                // Extract categories from the fetched data and set it
                const uniqueCategories = [...new Set(jsonData.map(item => item.category))]; // Get unique categories
                setCategories(uniqueCategories); // Update categories based on data
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    // Filter data based on search term and category selection
    const filteredData = data.filter(item => 
        (item.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        item.city.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!selectedCategory || item.category === selectedCategory)
    );

    // Logout function
    const handleLogout = () => {
        removeCookie('role', { path: '/' }); // Clear cookies
        removeCookie('username', { path: '/' });
        navigate('/login'); // Navigate to login
    };
    return (
        <div className="p-6 bg-gradient-to-r from-blue-500 to-purple-500 min-h-screen"> 
          <div className="container mx-auto max-w-screen-lg bg-white p-8 rounded-md shadow-md"> 
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">User Dashboard</h1>
    
            {/* Logout Button */}
            <button
              onClick={handleLogout}
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md shadow-md transition duration-300 mb-4"
            >
              Logout
            </button>
    
            {/* Dropdown Pilihan Kategori */}
            <div className="mb-4">
              <select
                className="w-full border border-gray-300 px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Choose Category</option>
                {categories.map((cat, 
     index) => (
                  <option key={index} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
    
            {/* Search Bar */}
            <div className="mb-4">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full   
     border border-gray-300   
     px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
    
            {/* Table Data */}
            <div className="overflow-x-auto">
              <table className="w-full table-auto border-collapse border border-gray-400">
                <thead>
                  <tr className="bg-blue-500   
     text-white">
                    <th className="border border-gray-400 px-4 py-2">ID</th>
                    <th className="border border-gray-400 px-4 py-2">Soal</th>
                    <th className="border border-gray-400 px-4 py-2">Jawaban</th>
                    <th className="border border-gray-400 px-4 py-2">Result</th>
                    <th className="border border-gray-400 px-4 py-2">Category</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map(item => (
                      <tr key={item.id} className="hover:bg-gray-100 transition">
                        <td className="border border-gray-400 px-4 py-2">{item.id}</td>
                        <td className="border border-gray-400 px-4 py-2">{item.name}</td>
                        <td className="border border-gray-400 px-4 py-2">{item.country}</td>
                        <td className="border border-gray-400 px-4 py-2">{item.city}</td>
                        <td className="border border-gray-400 px-4 py-2">{item.category}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="border border-gray-400 px-4 py-2 text-center">
                        No data found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    };
export default Dashboard;
