import React from 'react';

const NotFound = () => {
    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-4xl font-bold text-red-600">404</h1>
                <h2 className="text-2xl text-gray-800 mb-4">Page Not Found</h2>
                <p className="text-gray-600 mb-4">Sorry, the page you are looking for does not exist.</p>
                <a href="/" className="text-blue-600 hover:underline">Go back to Home</a>
            </div>
        </div>
    );
};

export default NotFound;
