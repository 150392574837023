import React from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const [, , removeCookie] = useCookies(['username', 'role']);
    const navigate = useNavigate();

    const handleLogout = () => {
        removeCookie('username', { path: '/' });
        removeCookie('role', { path: '/' });
        navigate('/login');
    };

    return (
        <button
            onClick={handleLogout}
            className="bg-red-600 text-white p-2 rounded shadow-md hover:bg-red-700 transition duration-300 mb-4"
        >
            Logout
        </button>
    );
};

export default Logout;